<template>
  <v-app>
    <app-header></app-header>

    <div data-spy="scroll" data-target="#navbar-app" data-offset="0">
      <div id="accueil">
        <accueil />
      </div>
      <div id="propos" class="mt-4">
        <thematique />
      </div>
      <div id="speakers">
        <bureau class="mt-5" :slideshow="slideshow" />
      </div>
      <div id="speakers">
        <evenements class="mt-5" :slideshow="slideshow" />
      </div>
      <!-- <div id="sponsors">
			<sponsors class="mt-5 "/>
		</div> -->
    </div>

    <app-footer></app-footer>
  </v-app>
</template>

<script>
import thematique from './landing/thematique.vue';
import accueil from './landing/accueil.vue';
import bureau from './landing/bureau.vue';
import evenements from './landing/evenements.vue';
import AppHeader from './appHeader.vue';
import AppFooter from './appFooter.vue';
export default {
  components: { thematique, accueil, bureau, evenements, AppHeader, AppFooter },

  data: () => ({
    scrollPosition: null,
    slideshow: "",
    dialog: false,
    vid: "",
    audio: true
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    goTo(path) {
      this.$router.push(path)
    },
    click: function () {
      this.$refs.inscriComponent.setValue();
    },

    slidenum(num) {
      this.slideshow = num

    },
  },
  mounted() {
  },

};
</script>
<style >

</style>