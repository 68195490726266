<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {

};
</script>
<style >
</style>