<template>
<div>
	<div class="social" style="text-align:right">
		<v-card class="social flex pa-1" max-width="40px" color="white">
			<a href="https://web.facebook.com/congresSMMIRP" target="_blank">
				<img height="29" width="29" class="img-responsive mb-1" src="../../assets/F.png" >
			</a>
		</v-card>
	</div>
	<v-img src="../../assets/footer.jpg" class="footpara" aspect-ratio="4.5">
		<div class="footparashad">
			<v-row >
				<v-col cols="12" md="4" sm="12"  align="center" class="mt-5 ">
					<a href="#" ><img  class="img-responsive Logofoter" src="../../assets/logo.png" height="100" width="280" ></a>
				</v-col>
				<v-col cols="12" md="8" sm="12" class="mt-sm-15 infofooter">
					<h2><b style="color:#41B9E3">Renseignements et inscription</b></h2>
					<span style="color:#b3ab98">Mme Batoula Rahmouni :</span> <a href="tel:+212664550909" class="contactAdn" style="text-decoration:none;">+212 664 550 909</a>
					<span style="color:#b3ab98"> - E-mail :</span> <a href="mailto:inscription@smmirp.ma" class="contactAdn" style="text-decoration:none;">inscription@smmirp.ma</a><br>
					<b style="color:#ff6054">Agence Hashtag Santé</b><br>
					<span style="color:#b3ab98">Mme Nouhaila Ibnoughafir :</span> <a href="tel:+212660442963" class="contactAdn" style="text-decoration:none;">+212 660 44 29 63</a>
					<span style="color:#b3ab98"> - E-mail :</span> <a href="mailto:nouhaila.ibnoughafir@ascrea.ma" class="contactAdn" style="text-decoration:none;">nouhaila.ibnoughafir@ascrea.ma</a><br>
					<span style="color:#b3ab98">Mr Khalil Sibari :</span> <a href="tel:+212602820075" class="contactAdn" style="text-decoration:none;">+212 602 82 00 75</a>
					<span style="color:#b3ab98"> - E-mail :</span> <a href="mailto:khalil.sibari@ascrea.ma" class="contactAdn" style="text-decoration:none;">khalil.sibari@ascrea.ma</a><br>
				</v-col>
			</v-row>
		</div>
	</v-img>
</div>
</template>

<script>
export default {

  data: () => ({
  }),
  methods: {
    
},
mounted() {
},

};
</script>
<style >

</style>