<template>
	<div>
		<v-container grid-list-xs>
			<v-row>
				<v-col align="center">
					<h1 class="propsHead compHead" style="color:#DF2530;font-family: 'Montserrat'">
						QUI SOMMES NOUS?
					</h1>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="pa-1" cols="12">
					<v-card class="rounded-xl" elevation="24" data-aos-once="true" data-aos="fade-down"
						data-aos-duration="1500">
						<div style="text-align: left;background-color:white;text-align: justify;text-justify: inter-word;transition: all 4s;font-family: 'Montserrat', sans-serif;"
							class="pa-5 text cardtext">
							<v-row class="mt-20">
								<v-col align="center">
									La Société Marocaine de Médecine Interne et Rhumatologie Pédiatriques est une
									association de professionnels de la santé, impliqués dans la prise en charge des
									rhumatismes juvéniles, des maladies de système, des vascularites, des maladies
									auto-inflammatoires et des maladies hématologiques et métaboliques à expression
									articulaire chez l'enfant. <br><br>
									<b style="color: #2D84B9;">Quels sont nos projets pour les patients ?</b><br>
									Donner la chance à ces patients atteints pour la plupart de maladies chroniques de
									mener une existence "normale" ; pouvoir jouer, aller à l'école, grandir, se
									construire et avoir confiance en soi. <br><br>
									<b style="color: #2D84B9;">Comment réussir ?</b><br>
									Augmenter les compétences nationales en Rhumatologie Pédiatrique, assurer la
									formation continue, renforcer le partenariat entre les différents intervenants dans
									la spécialité « pédiatres, chirurgiens orthopédistes pédiatres et ophtalmologistes
									», promouvoir la transition auprès des correspondants adultes, travailler en réseau
									à l’échelle nationale et internationale, créer un registre national de maladies
									rares au sein de la spécialité.
								</v-col>
							</v-row><br><br>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
export default {
	data() {
		return {
			more: false
		}
	},
}
</script>
<style >
.cardtext {
	font-size: 1.2em;
	color: #666666;
	border: 5px solid #DF2530;
}

.propo {
	text-align: center;
}

.hashtageimg {
	width: 200px;
}

@media all and (max-width: 768px) {

	.propsHead {
		font-size: 1.5rem;
		padding: .75rem;
		color: #EC5713
	}

	.hashtageimg {
		width: 120px;
	}
}

@media all and (max-width: 375px) {

	.hashtageimg {
		width: 120px;
	}
}
</style>