<template>
    <div>
        <app-header></app-header>
        <v-container>
            <v-card class="p-3" data-aos-once="true" data-aos="zoom-in" data-aos-duration="1500">
                <v-row class="safariFlex" align="center">
                    <v-col cols="12" md="6" sm="12" class="safariFlex-colum">
                        <v-img src="../../assets/invitation AMMIRP-01.jpg" aspect-ratio="0.6"
                            transition="slide-x-transition" alt="PLANIFICATION ET GESTION TERRITORIALE">
                        </v-img>
                    </v-col>
                    <v-col class="p-md-5 safariFlex-colum" cols="12" md="6" sm="12" align-self="center">
                        <h2 class="axeHead">Rhumatologie Pédiatrique et COVID 19</h2>
                        <div class="titleLine mb-2 ml-1"></div>
                        <div>
                            <v-chip class="ma-2" color="green" text-color="white" label>
                                <v-icon left>
                                    mdi-calendar-month-outline
                                </v-icon>
                                Mercredi 26 Mai 2021
                            </v-chip>
                            <v-chip class="ma-2" color="cyan" label text-color="white">
                                <v-icon left>
                                    mdi-map-marker
                                </v-icon>
                                En ligne (Webinaire)
                            </v-chip>
                            <v-chip style="text-decoration: none;" target="_blanc"
                                href="https://www.youtube.com/watch?v=K_yhxM93bH4&ab_channel=smmirpcongr%C3%A8s"
                                class="ma-2" color="red" label text-color="white">
                                <v-icon>mdi-youtube</v-icon>
                                Cliquez ici pour revoir le Webinaire
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="p-3 mt-3" data-aos-once="true" data-aos="zoom-in" data-aos-duration="1500">
                <v-row class="orderFix safariFlex">
                    <v-col id="a" class="p-md-5 safariFlex-colum" cols="12" md="6" sm="12" align-self="center">
                        <h2 class="axeHead">Maladie de Behcet Pédiatrique sous tous ses aspects</h2>
                        <div class="titleLine mb-2 ml-1"></div>
                        <div>
                            <v-chip class="ma-2" color="green" text-color="white" label>
                                <v-icon left>
                                    mdi-calendar-month-outline
                                </v-icon>
                                Vendredi 01 Avril 2022
                            </v-chip>
                            <v-chip class="ma-2" color="cyan" label text-color="white">
                                <v-icon left>
                                    mdi-map-marker
                                </v-icon>
                                Hôtel Le Casablanca - Casablanca
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col id="b" cols="12" md="6" sm="12" class="safariFlex-colum">
                        <v-img src="../../assets/invitation SMMIRP 01.jpg" aspect-ratio="0.6"
                            transition="scroll-x-reverse-transition" alt="TRANSFORMATION DIGITALE">
                        </v-img>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>

        <app-footer></app-footer>
    </div>
</template>
<script>
import AppFooter from '../appFooter.vue'
import appHeader from '../appHeader.vue'
export default {
    components: { appHeader, AppFooter },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
    },
}
</script>
<style >
.btn-multiline>span {
    width: 100%
}

.axeHead {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.9375rem;
    font-weight: 800
}

.titleLine {
    content: "";
    display: block;
    width: 4.375rem;
    height: .25rem;
    background-color: #2D84B9;
    bottom: 0;
    left: 0;
}


.orderFix #a {
    order: 1;
}

.orderFix #b {
    order: 2;
}


.orderFix #df {
    -webkit-order: 1;
}

.orderFix #cf {
    -webkit-order: 2;
}

@media screen and (min-width: 770px) {

    .safariFlex {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
    }

    .safariFlex-colum {
        flex: 0 0 50%;
    }

    .safariFlex-colum-foter {
        flex: 0 0 70%;
    }


}

@media all and (max-width: 767px) {

    .orderFix #a {
        order: 2;
    }

    .orderFix #b {
        order: 1;
    }

    /*  .orderFix #bf   {-webkit-order: 2;}
    .orderFix #af {-webkit-order: 1;}
    .orderFix #df   {-webkit-order: 2;}
    .orderFix #cf {-webkit-order: 1;} */

}

@media (max-width: 980px) {

    .axeHead {
        font-size: 1rem !important;
    }

}

@media (max-width: 390px) {

    .axeHead {
        font-size: 1.5rem !important;
    }

}
</style>