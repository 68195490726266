import { render, staticRenderFns } from "./appHeader.vue?vue&type=template&id=1b1112a0&"
import script from "./appHeader.vue?vue&type=script&lang=js&"
export * from "./appHeader.vue?vue&type=script&lang=js&"
import style0 from "./appHeader.vue?vue&type=style&index=0&id=1b1112a0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VList,VListItem,VListItemTitle,VMenu})
