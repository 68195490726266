<template>
<div>
    <v-container>
        <v-container grid-list-xs>
			<v-row>
				<v-col align="center">
					<h1 class="propsHead compHead" style="color:#E99A3B;font-family: 'Montserrat'"  >
						Bureau SMMIRP
					</h1>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col align="center">
					<h3 class="propsHead compHead" style="color:#2D84B9;font-family: 'Montserrat'"  >
						MEMBRES FONDATEURS
					</h3>
				</v-col>
			</v-row>
			<v-row class="mb-5">
				<v-col class="pa-1" cols="12">
					<v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
						<v-row>
							<v-col cols="12" md="3" sm="12"  class="person  text-center p-md-5 p-sm-3">
									<img src="..\..\assets\kenza.png" alt="Image" class="img-fluidmb-5 sp1Img" style="width: 150px; height: 150px;">
							</v-col>
							<v-col cols="12" md="9" sm="12" class="text-left pa-10">
								<h2 class="text-muted2" style="color:#B01185">Bouayed Kenza</h2>
								<h2 class="text-muted">Présidente</h2>
								<br>
								<p class="cvtext">
									Professeur de pédiatrie, rhumatologue pédiatrique.<br> 
									Chef du Service de Rhumatologie, Médecine Interne et Néphrologie Pédiatriques, Hôpital Universitaire Mère Enfant A. Harouchi, CHU Ibn Rochd, Casablanca, Maroc. Faculté de Méde-cine et de Pharmacie, Université Hassan 2, Casablanca, Maroc.<br>
									Présidente de la SMMIRP " Société Marocaine de Médecine Interne et de Rhumatologie Pédiatriques « .<br>
									Membre de la SOFREMIP " Société Francophone de Rhumatologie et des Maladies Inflammatoires Pédiatriques « .<br>
									Membre de la PRES "Société Européenne de Rhumatologie Pédiatrique", Membre du Comité Scientifique du Congrès PRES 2023.<br>
									Membre du Comité Scientifique de PAFLAR « Société africaine de Rhumatologie Pédia-trique ». <br>
									Reviewer pour le Pediatric Rheumatology Journal.<br>
									Vice-présidente de l'Association marocaine des malades atteints de lupus.<br>
									Secrétaire générale de l'Association "Ijri", "Association marocaine d'aide aux enfants atteints de maladies rhumatismales".<br>

								</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-10 mb-5">
				<v-col class="pa-1" cols="12">
					<v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
						<v-row>
							<v-col cols="12" md="3" sm="12" class="person  text-center p-md-5 p-sm-3">
									<img src="..\..\assets\Chkirate.jpg" alt="Image" class="img-fluidmb-5 sp2Img" style="width: 150px; height: 150px;">
							</v-col>
							<v-col cols="12" md="9" sm="12" class="text-left pa-10">
									<h2 class="text-muted2" style="color:#DF2530">Chkirate Bouchra</h2>
									<h2 class="text-muted">Vice-Présidente</h2>
									<br>
									<p class="cvtext">
									- Professeur de pédiatrie <br>
									- Chef de l unité de rhumatologie pédiatrique Hôpital d enfants CHU Ibn Sina Rabat <br>
									- Présidente de l’association ACOFE <br>
									- Membre du groupe SOFREMIP
									</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-10 mb-5">
				<v-col class="pa-1" cols="12">
					<v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
						<v-row>
							<v-col cols="12" md="3" sm="12" class="person  text-center p-md-5 p-sm-3">
									<img src="..\..\assets\Elhassani.png" alt="Image" class="img-fluidmb-5 sp3Img" style="width: 150px; height: 150px;">
							</v-col>
							<v-col cols="12" md="9" sm="12" class="text-left pa-10">
									<h2 class="text-muted2" style="color:#E99A3B">El Hassani Abdelkrim</h2>
									<h2 class="text-muted">Secrétaire général</h2>
									<br>
									<p class="cvtext">
									- Rhumatologue, secteur libéral, Rabatx<br>
									- Rhumatologie Pédiatrique depuis 2000/2001<br>
									- Président fondateur de l'Association des Rhumatologues privés de rabat<br>
									- Expertise en échographie ostéo-articulaire diagnostique et interventionnelle<br>
									</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-10 mb-5">
				<v-col class="pa-1" cols="12">
					<v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
						<v-row>
							<v-col cols="12" md="3" sm="12" class="person  text-center p-md-5 p-sm-3">
									<img src="\asmaa.png" alt="Image" class="img-fluidmb-5 sp4Img" style="width: 150px; height: 150px;">
							</v-col>
							<v-col cols="12" md="9" sm="12" class="text-left pa-10">
									<h2 class="text-muted2" style="color:#1D8E50">Sakhi Asmaa</h2>
									<h2 class="text-muted">Trésorière</h2>
									<br>
									<p class="cvtext">
									- Professeur assistant de pédiatrie. <br>
									- Rhumatologie médecine interne pédiatrique.<br> 
									- Maladies osseuses constitutionnelles <br>
									- Hôpital mère- enfant A.Harouchi <br>
									- Membre de  l'association  marocaine d'aide aux enfants <br>atteints de maladies rhumatismales<br>
									</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-10 mb-5">
				<v-col class="pa-1" cols="12">
					<v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
						<v-row>
							<v-col cols="12" md="3" sm="12" class="person  text-center p-md-5 p-sm-3">
									<img src="..\..\assets\Iman.png" alt="Image" class="img-fluidmb-5 sp5Img" style="width: 150px; height: 150px;">
							</v-col>
							<v-col cols="12" md="9" sm="12" class="text-left pa-10">
									<h2 class="text-muted2" style="color:#37AECE">Ait Sab Imane</h2>
									<h2 class="text-muted">Assesseur</h2>
									<br>
									<p class="cvtext">
									- Professeur de l’ enseignement supérieur <br>
									- Pédiatre <br>
									- Néphrologie- Rhumatologie- Médecine interne et Maladies Métaboliques <br>
									- Chef du service Pédiatrie B Hôpital mère -enfant CHU Mohamed VI Marrakech <br>
									</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row >
				<v-col align="center">
					<h3 class="propsHead compHead" style="color:#2D84B9;font-family: 'Montserrat'"  >
						PRÉSIDENTE D’HONNEUR
					</h3>
				</v-col>
			</v-row>
			<v-row class="mt-10 mb-5">
				<v-col class="pa-1" cols="12">
					<v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="zoom-in" data-aos-duration="1500">
						<v-row>
							<v-col cols="12" md="3" sm="12" class="person  text-center p-md-5 p-sm-3">
									<img src="..\..\assets\Nabiha.jpg" alt="Image" class="img-fluidmb-5 sp1Img" style="width: 150px; height: 150px;">
							</v-col>
							<v-col cols="12" md="9" sm="12" class="text-left pa-10">
									<h2 class="text-muted2" style="color:#B01185">Mikou Nabiha</h2>
									<h2 class="text-muted">Présidente d'honneur</h2>
									<br>
									<p class="cvtext">
									- Professeur de Pediatrie. <br>
									- Ancienne chef de service de pediatrie generale , rhumatologie et medecine interne pediatriques de 2006 à 2019. <br>
									- Presidente fondatrice de l’association marocaine d’aide aux enfants atteints de maladies rhumatismales : IJRI. <br>
									- Presidente de la fondation d’aide à l’hopital d’enfants A. Harouchi. <br>
									- Membre fondateur et redactrice en chef del la revue marocaine des maladies de l’enfant
									</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
    </v-container>
</div>  
</template>
<script>
export default {
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
      hideHand(){
        this.animation = false
      }
    },

    mounted() {
      
    
  },
}
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.v-card--reveal-first {
  align-items: center;
  top: 120px;
  justify-content: center;
  position: absolute;
  width: 100%;
  font-size:1.2em
}
.cvtext{
    font-size:1.1em;
	color: #666666;
    white-space: pre-line
}
.sp1Img{
	border: 5px solid #B01185;
	border-radius: 50%;
	padding: 5;
}
.sp2Img{
	border: 5px solid #DF2530;
	border-radius: 50%;
	padding: 5;
}
.sp3Img{
	border: 5px solid #E99A3B;
	border-radius: 50%;
	padding: 5;
}
.sp4Img{
	border: 5px solid #1D8E50;
	border-radius: 50%;
	padding: 5;
}
.sp5Img{
	border: 5px solid #37AECE;
	border-radius: 50%;
	padding: 5;
}

@media (min-width: 800px) and (max-width: 1100px) {
	.cvtext{
		display: block;
		font-size:1.1vw;
	}
	.v-card--reveal-first {
		font-size:1em
	}
}
@media (max-width: 767px) {
  .cvtext{
		display: block;
		font-size:0.9em;
	}
	.text-muted2{
		font-size:1.8em;
	}
	.text-muted{
		font-size:1.4em;
	}
}
@media (max-width: 400px) {
  .cvtext{
  display: block;
   font-size:0.8em;
}
}
@media (max-width: 360px) {
	.cvtext{
		display: block;
		font-size:0.7em;
	}
	.text-muted2{
		font-size:1.2em;
	}
	.text-muted{
		font-size:1.1em;
	}
}
.carros{
  position: relative;
}

.pulsate {
  position: absolute;
  top: 50%;
  left: 12.5%;
  animation-name: pulsate;
  animation: pulsate 5s infinite;
  animation-duration: 5s;
}

@media (max-width: 480px) {
  .pulsate {
      left: 45%;
    }
}




</style>