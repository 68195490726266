<template>
  <div class="header">
    <v-img src="..\..\assets\congres22\hakimimg.jpg" aspect-ratio="2.6" class="img-responsive center">
      <v-container>
        <v-row align="center">
          <v-col cols="12" md="6" class="p-2">
            <v-row data-aos-once="true" data-aos="flip-left">
              <v-col cols="12" align="center">
                <img class="img-responsive " src="..\..\assets\brand.png">
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" sm="12" @click="imgdialog = true" align="center">
            <image-shadow :src="this.mySrc" class="img-responsive center afficheAdn" class-name="example"
              :shadow-blur="10" shadow-hover>
            </image-shadow>
            <!-- <img  class="img-responsive center afficheAdn" @click="imgdialog = true"  src="..\..\assets\4.jpg" id="myImg" alt="hakim"> -->
            <v-dialog class="cardimg" v-model="imgdialog" max-width="600">
              <img class="img-responsive center" src="..\..\assets\congres22\4.jpg" alt="hakim">
            </v-dialog>
          </v-col>
          <v-col cols="12" align="center">
            <v-hover v-slot="{ hover }">
              <v-btn color="red" href="/" rounded dark x-large style="text-decoration: none;color:white!important">
                <v-icon class="mr-1" v-if="hover">
                  fa-arrow-left
                </v-icon>
                Page D'accueil
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <!-- <v-dialog  v-model="conclutDialog" width="800" height="600px" persistent>
        <v-card >
            <div style="text-align: left;background-color:white;text-align: justify;text-justify: inter-word;transition: all 4s;font-family: 'Montserrat', sans-serif;" class="p-3 text conclutCard " overflow>
                <v-row >
                  <v-col cols="12">
                    <h2 class="text-center"  style="color:red"><b>Mot de clôture</b></h2>
                  </v-col>
                    <v-col cols="12">
                        <b style="color: #2D84B9;">Chers participants,</b><br><br>
                        Le premier congrès de la SMMIRP a connu grâce à vous un franc succès. Les échanges y étaient
                        fructueux, ouverts et intéressants. Que de belles énergies.<br><br>
                        Aussi, je remercie tous les membres du bureau de m’avoir accompagnée dans le choix pertinent
                        des thématiques du programme scientifique.<br><br>
                        Au nom de tous les membres de la SMIIRP, je remercie les intervenants pour la qualité de leurs
                        présentations, un contenu consistant et une éloquence qui ont maintenu l’assistance en haleine et
                        suscité beaucoup d’échanges parmi les participants.<br><br>
                        Je remercie tous les participants dont la présence et l’intérêt porté aux thématiques ont rendu
                        l’échange riche et de haut niveau.<br><br>
                        Je réitère mes remerciements aux partenaires pharmaceutiques qui ont cru à ce projet, encouragé
                        et soutenu sa réalisation.<br><br>
                        Un grand merci à l’agence Ascrea et ses équipes pour le magnifique décor de scène et de la salle,
                        la qualité de l’écran de projection et l’organisation magistrale, preuve d’un grand
                        professionnalisme.<br><br>
                        Deux personnes à remercier nominativement: Mme Le professeur Sakhi Asmaa, rhumatologue
                        pédiatre à Casablanca au sein de mon équipe et trésorière de la SMMIRP, qui a travaillé à mes
                        cotés, de bout en bout, à l’organisation du congrès, ainsi que à Mme Rahmouni Batoula,
                        secrétaire du service qui a géré toutes vos inscriptions, répondu à vos questions et mails avec
                        beaucoup de patience et de gentillesse. Un merci chaleureux aux autres membres permanents de
                        mon équipe qui ont assuré consciencieusement la supervision des patients pendant la phase
                        d’organisation et de déroulement du congrès.<br><br>
                        Mille mercis à Mme Yasmina Sbihi, architecte soufi d’avoir apporté une note de rêve et de
                        spiritualité à notre congrès. Elle a su toucher les coeurs.<br><br>
                        Sans vous toutes et tous, nous n’aurions pu réussir cet événement.<br><br>
                        J’espère que cet air de bienveillance et de convivialité sera au rendez-vous lors des prochaines
                        éditions.<br><br>
                        Tous ces efforts ont pour but unique de prendre soin des patients pédiatriques atteints de maladies
                        rhumatologiques dans un système vertueux où la science fait foi.<br><br>
                    </v-col>
                    <v-col cols="12">
                      <b style="color: #2D84B9;text-align: right;">Kenza Bouayed <br>
                         Présidente de la SMMIRP et présidente du 1er congrès de la SMMIRP</b>
                    </v-col>
                </v-row><br><br>
            </div>  
            <v-card-actions>
                <v-btn @click="conclutDialog = false" outlined color="primary">Fermer</v-btn>
            </v-card-actions>
        </v-card> 
    </v-dialog> -->
  </div>
</template>

<script>
export default {
  name: 'accueil',
  data() {
    return {
      imgdialog: false,
      conclutDialog: true,
      mySrc: '/4.jpg',
      className: {
        type: String
      },
      shadowBlur: {
        type: Number,
        default: 20
      },
      shadowHover: {
        type: Boolean,
        default: false
      },
      shadowRadius: {
        type: Number,
        default: 8
      },
      src: {
        type: String,
        required: true
      },
      width: {
        type: Number,
        default: 300
      },
    }
  },

}
</script>
<style >
.block {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.tiemCard {
  padding: 10px;
  width: 500px
}

.digit {
  font-family: 'Roboto', serif;
  margin-top: 20%;
}

.view {
  text-align: center;
}

v-img.back {
  position: absolute;
  width: 100%;
}

nav.bottomNav {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  bottom: -19px;

}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

.conclutCard {
  flex-grow: 1;
  overflow: auto;
  height: 600px;
}


/* Large desktop */
@media (min-width: 1200px) {}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {}

/* Landscape phones and down */
@media (max-width: 480px) {
  .time {
    font-size: 0.7em
  }

  .digit {
    margin-top: 25%;
  }

  .tiemCard {
    width: 400px
  }

  .datetime {
    font-size: 1.3em
  }
}

.flip-clock__slot {
  color: #cca900;
}
</style>
