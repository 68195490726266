<template>
    <div>
    <v-container grid-list-xs>
        <v-row>
            <v-col align="center">
            <h1 style="color:#DF2530"  class="compHead">SPEAKERS</h1>
            <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-container class="pa-5 carros">
            <carousel :per-page-custom="responsive" :pagination-enabled="false" :navigation-enabled="true"  :scrollPerPage="false" :navigate-to="[slideshow,true]"	>
            <slide v-for="(speaker, index) in speakers" :key="index" :id="index" @click="hideHand" >
                <v-tooltip bottom color="#165274"  max-width="500">
                <template v-slot:activator="{ on, attrs }">
                <v-card class="mx-auto" color="grey lighten-4" max-width="300" @click="hideHand" @mouseover="hideHand" v-bind="attrs" v-on="on">
                <v-img aspect-ratio="1"  :src="'/speakers/'+speaker.src" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)">
                    <div class="d-flex v-card--reveal-first white--text ps-1" style="height: 60%;text-align:center" >
                    <b>{{speaker.name}}</b>
                    </div>
                </v-img>
                </v-card>
                </template>
                <span style="text-align:left;color:white" class="cvtext">{{speaker.cv}}</span>
                </v-tooltip>
            </slide>
            </carousel>
            <h1 class="pulsate" v-if="animation">
            <v-icon color="white" large>mdi-gesture-double-tap</v-icon>
            </h1>
        </v-container>
    </v-container>
    </div>  
</template>
<script>
    import { Carousel, Slide } from 'vue-carousel';
    export default {
    data() {
        return {
            overlay: false,
            selectslide:5,
            speakers: [
                {name:"Perrine Dusser",cv:"Fonctions :\n•	Praticien attachée, rhumatologie-pédiatrique, Kremlin Bicêtre\n•	Expertise en échographie articulaire pédiatrique\nActivite de recherche :\n•	CO-investigateur dans plusieurs essais cliniques :\n	Maladies auto-inflammatoires : épidémiologie/ Physiopathologie/ traitement\n	Maladies auto-immunes : épidémiologie/ traitements\n•	Expertise Inserm : fibromyalgie juvénile\nTravaux pédagogiques :\n•	Syndrome auto-inflammatoires au sein du traité EMC Appareil locomoteur. P.Dusser, C. Marsaud, I. Koné-Paut.\n•	Chapitre autres uvéites pédiatriques au sein du traité EMC uvéites. P.Dusser, I.Koné-Paut. \n•	Petty : Textbook of Pediatric Rheumatology, 8ème Edition, Chapitre 34. In press; D. Elefthe-riou, P. Dusser, I. Koné-Paut. \n•	Syndrome Auto-inflammatoire au sein de l'EMC (Encyclopédie médico-chirurgicale) traite-ment de l'appareil locomoteur. P. Dusser, I. Koné-Paut (en cours).\n Societes savantes-comite de lecture :\n•	Membre de la SOFREMIP (Société Francophone pour la Rhumatologie et les Maladies In-flammatoires Pédiatriques). Membre du conseil scientifique depuis 2016.\n•	Membre de la SFR (Société Française de Rhumatologie) depuis 2018.\n•	Membre de l'OMERACT \n•	Membre du réseau PRINTO \n•	Membre de la PreS (Paediatric Rheumatology European Society) \n•	Reviewer pour la Revue Joint Bone Spine",
                src:"dusser.jpg"},
                {name:"Rose Marie",cv:"•	Praticien hospitalier Rhumatologie CHU de Strasbourg - Hôpital de Haute pierre\n•	Très investie en pathologie osseuse sur le terrain clinique, dans l’enseignement et dans tous les projets de recherche qu’ils soient régionaux, nationaux et européens avec le bureau scientifique du GRIO et les centres Maladies Rares.\n•	Responsable de l’Unité de Densitométrie osseuse (1500 examens par an et stage pratique du DU du GRIO) , du Centre de Compétence de la Dysplasie fibreuse, du Centre de Compétence de la Mastocytose osseuse.\n•	Co-responsable du Centre de Compétence des Maladies osseuses Constitutionnelles.\n•	Responsable de la Filière Parcours OP , créée en 2010 , médaillée de bronze par l’IOF.",
                src:"mari.jpg"},
                {name:"Véronique Hentgen",cv:"•	Praticien Hospitalier (Pédiatrie), CH Versailles depuis 2004.\nActivites  de  recherche\n•	Coordination de la cohorte Européenne JIR des fièvres récurrentes auto-inflammatoires (depuis 2016).\n•	Conseil de direction et conseil scientifique de la JIRcohorte (depuis 2014), Vice-présidente de la JIRcohorte (depuis 2021).\n•	Nombre de publications dans PubMed : 128 (dont 22 en tant que investigateur)\nEnseignement\n•	Enseignement dans le cours européen EULAR et PReS (depuis 2011 et 2014 respectivement)\n•	Co-responsable pédagogique du DIU des maladies autoinflammatoires (depuis 2016)\nResponsabilites, societes savantes, expertises\n•	Secrétaire de la société française de rhumatologie et maladies inflammatoires pédiatriques - SOFREMIP (2015-2021)<br>•	Responsable du groupe de travail « autoinflammation » de la PReS (depuis 2018)",
                src:"hentgen.jpg"},
                {name:"Isabelle Koné-Paut",cv:"Le professeur Isabelle Koné-Paut est à la tête d'un service de rhumatologie pédiatrique de renommée mondiale à l'hôpital du Kremlin Bicêtre, université Paris Sud-Saclay. Son service a reçu le prix EULAR Center of Excellence 2015/2020.\n Le Pr Koné-Paut coordonne le centre national de référence des maladies rares auto-inflammatoires et des amyloses inflammatoires (CEREMAI puis CEREMAIA (http://www.ceremaia.fr) depuis 2007.\n Particulièrement investi dans la recherche clinique, le professeur Koné-Paut collabore à de nombreuses initiatives européennes et internationales : EUROFEVER, cohorte JIR, PEDBD, IMMUNAID, et elle a développé avec son équipe le premier score de mesure de l'activité des maladies auto-inflammatoires : AIDAI.\n Son service a initié et / ou participé à plus de 30 essais cliniques et thérapeutiques depuis 2006. Le Professeur Koné-Paut est l'auteur de nombreuses publications internationales dont les plus significatives concernent la maladie de Behçet, la fièvre méditerranéenne familiale, les syndromes CAPS et la maladie de Kawasaki.\n Elle a occupé diverses fonctions au sein de sociétés académiques : Présidente de la SOFREMIP (société française de rhumatologie pédiatrique), membre du conseil d'administration de la société française de pédiatrie, du PRES, de l'ISSAID, de la société internationale de Behçet (ISBD).\n Le Professeur Koné-Paut a créé le premier réseau de soins des rhumatismes inflammatoires pédiatriques dans la région de Paris ile de France en 2012. Elle a créé avec le Pr Touitou un diplôme inter universitaire des maladies auto-inflammatoires en 2016.",
                src:"isabelle.jpg"},
                {name:"Bouayed Kenza",cv:"Professeur de pédiatrie, rhumatologue pédiatrique.  Chef du Service de Rhumatologie, Médecine Interne et Néphrologie Pédiatriques, Hôpital Universitaire Mère Enfant A. Harouchi, CHU Ibn Rochd, Casablanca, Maroc.\n Faculté de Méde-cine et de Pharmacie, Université Hassan 2, Casablanca, Maroc.\n Présidente de la SMMIRP ' Société Marocaine de Médecine Interne et de Rhumatologie Pédiatriques « .\n Membre de la SOFREMIP ' Société Francophone de Rhumatologie et des Maladies Inflammatoires Pédiatriques « .\n Membre de la PRES 'Société Européenne de Rhumatologie Pédiatrique', Membre du Comité Scientifique du Congrès PRES 2023\n Membre du Comité Scientifique de PAFLAR « Société africaine de Rhumatologie Pédia-trique ».\n Reviewer pour le Pediatric Rheumatology Journal.\n Vice-présidente de l'Association marocaine des malades atteints de lupus.\n Secrétaire générale de l'Association 'Ijri', 'Association marocaine d'aide aux enfants atteints de maladies rhumatismales.'\n",
                src:"kenza.jpg"},
                {name:"Ait Sab Imane",cv:"- Professeur de l’ enseignement supérieur \n - Pédiatre \n - Néphrologie- Rhumatologie- Médecine interne et Maladies Métaboliques \n - Chef du service Pédiatrie B Hôpital mère -enfant CHU Mohamed VI Marrakech \n",
                src:"Iman.png"},
                {name:"Chkirate Bouchra",cv:"- Professeur de pédiatrie \n- Chef de l unité de rhumatologie pédiatrique Hôpital d enfants CHU Ibn Sina Rabat \n- Présidente de l’association ACOFE \n- Membre du groupe SOFREMIP",
                src:"chkirate.jpg"},
                {name:"Perrine Dusser",cv:"Fonctions :\n•	Praticien attachée, rhumatologie-pédiatrique, Kremlin Bicêtre\n•	Expertise en échographie articulaire pédiatrique\nActivite de recherche :\n•	CO-investigateur dans plusieurs essais cliniques :\n	Maladies auto-inflammatoires : épidémiologie/ Physiopathologie/ traitement\n	Maladies auto-immunes : épidémiologie/ traitements\n•	Expertise Inserm : fibromyalgie juvénile\nTravaux pédagogiques :\n•	Syndrome auto-inflammatoires au sein du traité EMC Appareil locomoteur. P.Dusser, C. Marsaud, I. Koné-Paut.\n•	Chapitre autres uvéites pédiatriques au sein du traité EMC uvéites. P.Dusser, I.Koné-Paut. \n•	Petty : Textbook of Pediatric Rheumatology, 8ème Edition, Chapitre 34. In press; D. Elefthe-riou, P. Dusser, I. Koné-Paut. \n•	Syndrome Auto-inflammatoire au sein de l'EMC (Encyclopédie médico-chirurgicale) traite-ment de l'appareil locomoteur. P. Dusser, I. Koné-Paut (en cours).\n Societes savantes-comite de lecture :\n•	Membre de la SOFREMIP (Société Francophone pour la Rhumatologie et les Maladies In-flammatoires Pédiatriques). Membre du conseil scientifique depuis 2016.\n•	Membre de la SFR (Société Française de Rhumatologie) depuis 2018.\n•	Membre de l'OMERACT \n•	Membre du réseau PRINTO \n•	Membre de la PreS (Paediatric Rheumatology European Society) \n•	Reviewer pour la Revue Joint Bone Spine",
                src:"dusser.jpg"},
                {name:"Rose Marie",cv:"•	Praticien hospitalier Rhumatologie CHU de Strasbourg - Hôpital de Haute pierre\n•	Très investie en pathologie osseuse sur le terrain clinique, dans l’enseignement et dans tous les projets de recherche qu’ils soient régionaux, nationaux et européens avec le bureau scientifique du GRIO et les centres Maladies Rares.\n•	Responsable de l’Unité de Densitométrie osseuse (1500 examens par an et stage pratique du DU du GRIO) , du Centre de Compétence de la Dysplasie fibreuse, du Centre de Compétence de la Mastocytose osseuse.\n•	Co-responsable du Centre de Compétence des Maladies osseuses Constitutionnelles.\n•	Responsable de la Filière Parcours OP , créée en 2010 , médaillée de bronze par l’IOF.",
                src:"mari.jpg"},
                {name:"Véronique Hentgen",cv:"•	Praticien Hospitalier (Pédiatrie), CH Versailles depuis 2004.\nActivites  de  recherche\n•	Coordination de la cohorte Européenne JIR des fièvres récurrentes auto-inflammatoires (depuis 2016).\n•	Conseil de direction et conseil scientifique de la JIRcohorte (depuis 2014), Vice-présidente de la JIRcohorte (depuis 2021).\n•	Nombre de publications dans PubMed : 128 (dont 22 en tant que investigateur)\nEnseignement\n•	Enseignement dans le cours européen EULAR et PReS (depuis 2011 et 2014 respectivement)\n•	Co-responsable pédagogique du DIU des maladies autoinflammatoires (depuis 2016)\nResponsabilites, societes savantes, expertises\n•	Secrétaire de la société française de rhumatologie et maladies inflammatoires pédiatriques - SOFREMIP (2015-2021)<br>•	Responsable du groupe de travail « autoinflammation » de la PReS (depuis 2018)",
                src:"hentgen.jpg"},
                {name:"Isabelle Koné-Paut",cv:"Le professeur Isabelle Koné-Paut est à la tête d'un service de rhumatologie pédiatrique de renommée mondiale à l'hôpital du Kremlin Bicêtre, université Paris Sud-Saclay. Son service a reçu le prix EULAR Center of Excellence 2015/2020.\n Le Pr Koné-Paut coordonne le centre national de référence des maladies rares auto-inflammatoires et des amyloses inflammatoires (CEREMAI puis CEREMAIA (http://www.ceremaia.fr) depuis 2007.\n Particulièrement investi dans la recherche clinique, le professeur Koné-Paut collabore à de nombreuses initiatives européennes et internationales : EUROFEVER, cohorte JIR, PEDBD, IMMUNAID, et elle a développé avec son équipe le premier score de mesure de l'activité des maladies auto-inflammatoires : AIDAI.\n Son service a initié et / ou participé à plus de 30 essais cliniques et thérapeutiques depuis 2006. Le Professeur Koné-Paut est l'auteur de nombreuses publications internationales dont les plus significatives concernent la maladie de Behçet, la fièvre méditerranéenne familiale, les syndromes CAPS et la maladie de Kawasaki.\n Elle a occupé diverses fonctions au sein de sociétés académiques : Présidente de la SOFREMIP (société française de rhumatologie pédiatrique), membre du conseil d'administration de la société française de pédiatrie, du PRES, de l'ISSAID, de la société internationale de Behçet (ISBD).\n Le Professeur Koné-Paut a créé le premier réseau de soins des rhumatismes inflammatoires pédiatriques dans la région de Paris ile de France en 2012. Elle a créé avec le Pr Touitou un diplôme inter universitaire des maladies auto-inflammatoires en 2016.",
                src:"isabelle.jpg"},
                {name:"Bouayed Kenza",cv:"Professeur de pédiatrie, rhumatologue pédiatrique.  Chef du Service de Rhumatologie, Médecine Interne et Néphrologie Pédiatriques, Hôpital Universitaire Mère Enfant A. Harouchi, CHU Ibn Rochd, Casablanca, Maroc.\n Faculté de Méde-cine et de Pharmacie, Université Hassan 2, Casablanca, Maroc.\n Présidente de la SMMIRP ' Société Marocaine de Médecine Interne et de Rhumatologie Pédiatriques « .\n Membre de la SOFREMIP ' Société Francophone de Rhumatologie et des Maladies Inflammatoires Pédiatriques « .\n Membre de la PRES 'Société Européenne de Rhumatologie Pédiatrique', Membre du Comité Scientifique du Congrès PRES 2023\n Membre du Comité Scientifique de PAFLAR « Société africaine de Rhumatologie Pédia-trique ».\n Reviewer pour le Pediatric Rheumatology Journal.\n Vice-présidente de l'Association marocaine des malades atteints de lupus.\n Secrétaire générale de l'Association 'Ijri', 'Association marocaine d'aide aux enfants atteints de maladies rhumatismales.'\n",
                src:"kenza.jpg"},
                {name:"Ait Sab Imane",cv:"- Professeur de l’ enseignement supérieur \n - Pédiatre \n - Néphrologie- Rhumatologie- Médecine interne et Maladies Métaboliques \n - Chef du service Pédiatrie B Hôpital mère -enfant CHU Mohamed VI Marrakech \n",
                src:"Iman.png"},
                {name:"Chkirate Bouchra",cv:"- Professeur de pédiatrie \n- Chef de l unité de rhumatologie pédiatrique Hôpital d enfants CHU Ibn Sina Rabat \n- Présidente de l’association ACOFE \n- Membre du groupe SOFREMIP",
                src:"chkirate.jpg"},
                {name:"Perrine Dusser",cv:"Fonctions :\n•	Praticien attachée, rhumatologie-pédiatrique, Kremlin Bicêtre\n•	Expertise en échographie articulaire pédiatrique\nActivite de recherche :\n•	CO-investigateur dans plusieurs essais cliniques :\n	Maladies auto-inflammatoires : épidémiologie/ Physiopathologie/ traitement\n	Maladies auto-immunes : épidémiologie/ traitements\n•	Expertise Inserm : fibromyalgie juvénile\nTravaux pédagogiques :\n•	Syndrome auto-inflammatoires au sein du traité EMC Appareil locomoteur. P.Dusser, C. Marsaud, I. Koné-Paut.\n•	Chapitre autres uvéites pédiatriques au sein du traité EMC uvéites. P.Dusser, I.Koné-Paut. \n•	Petty : Textbook of Pediatric Rheumatology, 8ème Edition, Chapitre 34. In press; D. Elefthe-riou, P. Dusser, I. Koné-Paut. \n•	Syndrome Auto-inflammatoire au sein de l'EMC (Encyclopédie médico-chirurgicale) traite-ment de l'appareil locomoteur. P. Dusser, I. Koné-Paut (en cours).\n Societes savantes-comite de lecture :\n•	Membre de la SOFREMIP (Société Francophone pour la Rhumatologie et les Maladies In-flammatoires Pédiatriques). Membre du conseil scientifique depuis 2016.\n•	Membre de la SFR (Société Française de Rhumatologie) depuis 2018.\n•	Membre de l'OMERACT \n•	Membre du réseau PRINTO \n•	Membre de la PreS (Paediatric Rheumatology European Society) \n•	Reviewer pour la Revue Joint Bone Spine",
                src:"dusser.jpg"},
                {name:"Rose Marie",cv:"•	Praticien hospitalier Rhumatologie CHU de Strasbourg - Hôpital de Haute pierre\n•	Très investie en pathologie osseuse sur le terrain clinique, dans l’enseignement et dans tous les projets de recherche qu’ils soient régionaux, nationaux et européens avec le bureau scientifique du GRIO et les centres Maladies Rares.\n•	Responsable de l’Unité de Densitométrie osseuse (1500 examens par an et stage pratique du DU du GRIO) , du Centre de Compétence de la Dysplasie fibreuse, du Centre de Compétence de la Mastocytose osseuse.\n•	Co-responsable du Centre de Compétence des Maladies osseuses Constitutionnelles.\n•	Responsable de la Filière Parcours OP , créée en 2010 , médaillée de bronze par l’IOF.",
                src:"mari.jpg"},
                {name:"Véronique Hentgen",cv:"•	Praticien Hospitalier (Pédiatrie), CH Versailles depuis 2004.\nActivites  de  recherche\n•	Coordination de la cohorte Européenne JIR des fièvres récurrentes auto-inflammatoires (depuis 2016).\n•	Conseil de direction et conseil scientifique de la JIRcohorte (depuis 2014), Vice-présidente de la JIRcohorte (depuis 2021).\n•	Nombre de publications dans PubMed : 128 (dont 22 en tant que investigateur)\nEnseignement\n•	Enseignement dans le cours européen EULAR et PReS (depuis 2011 et 2014 respectivement)\n•	Co-responsable pédagogique du DIU des maladies autoinflammatoires (depuis 2016)\nResponsabilites, societes savantes, expertises\n•	Secrétaire de la société française de rhumatologie et maladies inflammatoires pédiatriques - SOFREMIP (2015-2021)<br>•	Responsable du groupe de travail « autoinflammation » de la PReS (depuis 2018)",
                src:"hentgen.jpg"},
                {name:"Isabelle Koné-Paut",cv:"Le professeur Isabelle Koné-Paut est à la tête d'un service de rhumatologie pédiatrique de renommée mondiale à l'hôpital du Kremlin Bicêtre, université Paris Sud-Saclay. Son service a reçu le prix EULAR Center of Excellence 2015/2020.\n Le Pr Koné-Paut coordonne le centre national de référence des maladies rares auto-inflammatoires et des amyloses inflammatoires (CEREMAI puis CEREMAIA (http://www.ceremaia.fr) depuis 2007.\n Particulièrement investi dans la recherche clinique, le professeur Koné-Paut collabore à de nombreuses initiatives européennes et internationales : EUROFEVER, cohorte JIR, PEDBD, IMMUNAID, et elle a développé avec son équipe le premier score de mesure de l'activité des maladies auto-inflammatoires : AIDAI.\n Son service a initié et / ou participé à plus de 30 essais cliniques et thérapeutiques depuis 2006. Le Professeur Koné-Paut est l'auteur de nombreuses publications internationales dont les plus significatives concernent la maladie de Behçet, la fièvre méditerranéenne familiale, les syndromes CAPS et la maladie de Kawasaki.\n Elle a occupé diverses fonctions au sein de sociétés académiques : Présidente de la SOFREMIP (société française de rhumatologie pédiatrique), membre du conseil d'administration de la société française de pédiatrie, du PRES, de l'ISSAID, de la société internationale de Behçet (ISBD).\n Le Professeur Koné-Paut a créé le premier réseau de soins des rhumatismes inflammatoires pédiatriques dans la région de Paris ile de France en 2012. Elle a créé avec le Pr Touitou un diplôme inter universitaire des maladies auto-inflammatoires en 2016.",
                src:"isabelle.jpg"},
                {name:"Bouayed Kenza",cv:"Professeur de pédiatrie, rhumatologue pédiatrique.  Chef du Service de Rhumatologie, Médecine Interne et Néphrologie Pédiatriques, Hôpital Universitaire Mère Enfant A. Harouchi, CHU Ibn Rochd, Casablanca, Maroc.\n Faculté de Méde-cine et de Pharmacie, Université Hassan 2, Casablanca, Maroc.\n Présidente de la SMMIRP ' Société Marocaine de Médecine Interne et de Rhumatologie Pédiatriques « .\n Membre de la SOFREMIP ' Société Francophone de Rhumatologie et des Maladies Inflammatoires Pédiatriques « .\n Membre de la PRES 'Société Européenne de Rhumatologie Pédiatrique', Membre du Comité Scientifique du Congrès PRES 2023\n Membre du Comité Scientifique de PAFLAR « Société africaine de Rhumatologie Pédia-trique ».\n Reviewer pour le Pediatric Rheumatology Journal.\n Vice-présidente de l'Association marocaine des malades atteints de lupus.\n Secrétaire générale de l'Association 'Ijri', 'Association marocaine d'aide aux enfants atteints de maladies rhumatismales.'\n",
                src:"kenza.jpg"},
                {name:"Ait Sab Imane",cv:"- Professeur de l’ enseignement supérieur \n - Pédiatre \n - Néphrologie- Rhumatologie- Médecine interne et Maladies Métaboliques \n - Chef du service Pédiatrie B Hôpital mère -enfant CHU Mohamed VI Marrakech \n",
                src:"Iman.png"},
                {name:"Chkirate Bouchra",cv:"- Professeur de pédiatrie \n- Chef de l unité de rhumatologie pédiatrique Hôpital d enfants CHU Ibn Sina Rabat \n- Présidente de l’association ACOFE \n- Membre du groupe SOFREMIP",
                src:"chkirate.jpg"},
                
            ],
            responsive: [
                [200, 1],[767, 2],[1000, 3]
            ],
            animation:true,
            mySrc: '/4.jpg',
            className: {
            type: String
            },
            shadowBlur: {
            type: Number,
            default: 20
            },
            shadowHover: {
            type: Boolean,
            default: false
            },
            shadowRadius: {
            type: Number,
            default: 8
            },
            src: {
            type: String,
            required: true
            },
            width: {
            type: Number,
            default: 200
            },
            }
    },
    props: ['slideshow'],
    components: {
        Carousel,
        Slide
    },
    methods: {
    hideHand(){
        this.animation = false
    }
    },

    mounted() {


    },
    }
    </script>
    <style>
    .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
    }
    .v-card--reveal-first {
    align-items: center;
    top: 120px;
    justify-content: center;
    position: absolute;
    width: 100%;
    font-size:1.2em
    }
    .cvtext{
    display: block;
    font-size:0.9em;
    white-space: pre-line
    }
    @media (min-width: 800px) and (max-width: 1100px) {
    .cvtext{
    display: block;
    font-size:1.1vw;
    }
    .v-card--reveal-first {
    font-size:1em
    }
    }
    @media (max-width: 767px) {
    .cvtext{
    display: block;
    font-size:0.9em;
    }
    }
    @media (max-width: 767px) {
    .cvtext{
    display: block;
    font-size:0.9em;
    }
    }
    @media (max-width: 400px) {
    .cvtext{
    display: block;
    font-size:0.8em;
    }
    }
    @media (max-width: 360px) {
    .cvtext{
    display: block;
    font-size:0.7em;
    }
    }
    .carros{
    position: relative;
    }
    @-webkit-keyframes pulsate {
    from {
    opacity: 1;
    }
    10% {
    opacity: 0;
    }
    20% {
    opacity: 1;
    }
    30% {
    opacity: 0;
    }
    40% {
    opacity: 1;
    }
    50% {
    opacity: 0;
    }
    60% {
    opacity: 1;
    }
    70% {
    opacity: 0;
    }
    80% {
    opacity: 1;
    }
    90% {
    opacity: 0;
    }
    to {
    opacity: 0;
    }
    }

    .pulsate {
    position: absolute;
    top: 50%;
    left: 12.5%;
    animation-name: pulsate;
    animation: pulsate 5s infinite;
    animation-duration: 5s;
    }

    @media (max-width: 480px) {
    .pulsate {
    left: 45%;
    }
    }

</style>