<template>
    <div>
        <v-card>
            <div class="date p-2">
                <strong>Vendredi 14 Octobre 2022</strong>
            </div>

            <v-timeline align-top dense>
                <v-timeline-item color="#322E82" small>

                    <v-row>
                        <v-col cols="12" md="9" sm="12" align="left">
                            <span class="sessionTitre ">Les uvéites pédiatriques non infectieuses</span><br>
                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                <v-avatar>
                                    <img src="../../assets/avt.png" alt="Fabienne">
                                </v-avatar>
                                <span class="ml-2 orateurs"> Kenza Bouayed</span>
                            </a>
                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">

                            <div>
                                <br>
                                <v-hover v-slot="{ hover }">
                                    <v-btn class="btn btn-primary" v-on:click="showModal = true"
                                        style="text-decoration: none;color:white!important" color="#3F8EBC" rounded
                                        dark>Lire video
                                        <v-icon class="ml-1" v-if="hover">
                                            fa-arrow-right
                                        </v-icon>
                                    </v-btn>
                                </v-hover>

                            </div>
                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">

                            <div class="hus">

                                <modal v-if="showModal" @close="showModal = false">
                                    <iframe class="video" width="560" height="315"
                                        src="https://www.youtube.com/embed/DjHfeoejU_g" title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                    <v-hover v-slot="{ hover }">
                                        <v-btn class="fermer" v-on:click="showModal = false"
                                            style="text-decoration: none;color:white!important" color="red" rounded
                                            dark>Fermer
                                            <v-icon class="ml-1" v-if="hover">
                                                fa-arrow-right
                                            </v-icon>
                                        </v-btn>
                                    </v-hover>
                                </modal>
                            </div>
                        </v-col>
                    </v-row>
                </v-timeline-item>
                <v-timeline-item color="#322E82" small>

                    <v-row>
                        <v-col cols="12" align="left">
                            <span class="sessionTitre ">Accueil des participants - Café D’accueil </span><br>
                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="right">

                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">


                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">


                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">
                            <img width="100" src="../../assets/congres22/abbvie.png" alt="Fabienne">

                        </v-col>
                    </v-row>
                </v-timeline-item>
                <v-timeline-item color="#322E82" small>

                    <v-row>
                        <v-col cols="12" align="left">
                            <span class="sessionTitre ">Mot de bienvenue </span><br>
                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                <v-avatar>
                                    <img src="../../assets/avt.png" alt="Fabienne">
                                </v-avatar>
                                <span class="ml-2 orateurs"> Kenza Bouayed</span>
                            </a>
                        </v-col>
                    </v-row>
                </v-timeline-item>
                <v-timeline-item color="#322E82" small>

                    <v-row>
                        <v-col cols="12" align="left">
                            <span class="sessionTitre ">Mot du président de la SMP </span><br>
                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                <v-avatar>
                                    <img src="../../assets/avt.png" alt="Fabienne">
                                </v-avatar>
                                <span class="ml-2 orateurs"> Afilal Hassan</span>
                            </a>
                        </v-col>
                    </v-row>
                </v-timeline-item>
                <v-timeline-item color="#322E82" small>

                    <v-row>
                        <v-col cols="12" md="9" sm="12" align="left">
                            <span class="sessionTitre " style="color:#000!important">CONFÉRENCE D’INAUGURATION
                            </span><br>
                            <span class="sessionTitre ">L’os chez l’enfant</span><br>
                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                <v-avatar>
                                    <img src="../../assets/avt.png" alt="Fabienne">
                                </v-avatar>
                                <span class="ml-2 orateurs"> Rose Marie Javier</span>
                            </a>
                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">
                            <div>
                                <br>
                                <v-hover v-slot="{ hover }">
                                    <v-btn class="btn btn-primary" v-on:click="showModal1 = true"
                                        style="text-decoration: none;color:white!important" color="#3F8EBC" rounded
                                        dark>Lire
                                        video
                                        <v-icon class="ml-1" v-if="hover">
                                            fa-arrow-right
                                        </v-icon>
                                    </v-btn>
                                </v-hover>

                            </div>
                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">
                            <div class="hus">

                                <modal v-if="showModal1" @close="showModal1 = false">
                                    <iframe class="video" width="560" height="315"
                                        src="https://www.youtube.com/embed/tawqOtlld5k" title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                    <v-hover v-slot="{ hover }">
                                        <v-btn class="fermer" v-on:click="showModal1 = false"
                                            style="text-decoration: none;color:white!important" color="red" rounded
                                            dark>Fermer
                                            <v-icon class="ml-1" v-if="hover">
                                                fa-arrow-right
                                            </v-icon>
                                        </v-btn>
                                    </v-hover>
                                </modal>
                            </div>
                        </v-col>
                    </v-row>
                </v-timeline-item>
                <v-timeline-item color="#322E82" small>

                    <v-row>
                        <v-col cols="12" md="9" sm="12" align="left">
                            <span class="sessionTitre " style="color:#000!important">CONFÉRENCE D’ACTUALITÉ
                            </span><br>
                            <span class="sessionTitre ">Biothérapie dans l’AJI</span><br>
                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                <v-avatar>
                                    <img src="../../assets/avt.png" alt="Fabienne">
                                </v-avatar>
                                <span class="ml-2 orateurs"> Isabelle Koné-Paut</span>
                            </a>
                        </v-col>


                        <v-col cols="12" md="3" sm="12" align="center">
                            <div>
                                <br>
                                <v-hover v-slot="{ hover }">

                                    <v-btn class="btn btn-primary" v-on:click="showModal2 = true"
                                        style="text-decoration: none;color:white!important" color="#3F8EBC" rounded
                                        dark>Lire
                                        video
                                        <v-icon class="ml-1" v-if="hover">
                                            fa-arrow-right
                                        </v-icon>
                                    </v-btn>
                                </v-hover>

                            </div>
                        </v-col>
                        <v-col cols="12" md="3" sm="12" align="center">
                            <div class="hus">

                                <modal v-if="showModal2" @close="showModal2 = false">
                                    <iframe class="video" width="560" height="315"
                                        src="https://www.youtube.com/embed/qzytotkEnPs" title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                    <v-hover v-slot="{ hover }">
                                        <v-btn class="fermer" v-on:click="showModal2 = false"
                                            style="text-decoration: none;color:white!important" color="red" rounded
                                            dark>Fermer
                                            <v-icon class="ml-1" v-if="hover">
                                                fa-arrow-right
                                            </v-icon>
                                        </v-btn>
                                    </v-hover>
                                </modal>
                            </div>
                        </v-col>


                    </v-row>
                </v-timeline-item>
            </v-timeline>
        </v-card>
    </div>
</template>
<script>


export default {

    metaInfo() {
        return {

        }
    },
    methods: {
        slideTo(num) {
            this.$emit('gotoslide', num)
        }
    },

}
</script>
<style >
.fermer {
    position: relative;
    left: 150px;
}

.hus {
    position: relative;
    left: 150px;
}

.sessionTitre {
    color: #322E82;
    font-size: 1.2em;
    font-weight: 500;
}

.titreline {
    margin-left: 60px !important;
    color: #231F20;
    font-size: 1.5em
}

.titreline2 {
    margin-left: 60px !important;
    color: #1B84A5;
    font-size: 1.5em
}

.heurire {
    max-width: 120px;
    color: white;
    background-color: #322E82;
    font-size: 16px;
    text-align: center;
}

.heurire2 {
    max-width: 120px;
    color: white;
    background-color: #3F8EBC;
    font-size: 16px;
    text-align: center;
}

.date {
    font-family: 'Montserrat', sans-serif;
    color: white;
    background-color: #3F8EBC;
    font-size: 19px;
}

.pauseLine {
    font-size: 1.3em;
}

@media all and (max-width: 768px) {
    .fermer {
        position: relative;
        left: 30px;
    }

    .video {
        width: 400px;
        height: 250;

    }

    .hus {
        position: relative;
        left: -50px;
        resize: both;
        width: 350px;
    }

    .horaire {
        font-size: 0.9em;
        padding-left: 1px !important;
    }

    .titreline {
        margin-left: 60px !important;
        color: #231F20;
        font-size: 1em
    }

    .titreline2 {
        margin-left: 20px !important;
        color: #1B84A5;
        font-size: 1.2em
    }

    .sessionTitre {
        font-size: 0.9em;
    }

}


@media all and (max-width: 280px) {


    .sessionTitre {
        font-size: 0.6em;
    }

}
</style>

<script>
export default {
  data() {
    return {
      showModal: false,
      showModal1: false,
      showModal2: false,
    //   videoId: 'abcdefghijk'
    }
  },
  
  
}
</script>