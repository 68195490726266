<template>
<div>
	<nav style="background-color:#2D84B9;height:30px" class="pa-2 ">
	</nav>
	<nav style="background-color:white" id="myHeader" :class='{headclass}' class="navbar navbar-light navbar-expand-md bg-faded justify-content-center sticky-top ">
		<a href="/" class="d-flex  mr-auto">
			<img  class="img-responsive logonav"  src="../assets/smLogo.png"  >
		</a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
		<span class="navbar-toggler-icon"></span>
		</button>
		<div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
			<ul class="nav navbar-nav  ml-auto justify-content-center first">
				<li>
					<a href="/landingpage#" id="l1"  >
						<b>Accueil</b>
					</a>
				</li>
				<li>
					<v-menu  open-on-hover buttom offset-y >
						<template v-slot:activator="{ on, attrs }">
							<a  id="l4" v-bind="attrs" v-on="on" >
								<b>Evénements SMMIRP</b>
							</a>
						</template>
						<v-list color="#2D84B9">
							<v-list-item>
								<v-list-item-title>
									<a href="/tableronde" style="font-size:14px!important" class="selectnav" >
										<b >Nos tables rondes</b>
									</a>
								</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title>
									<a href="#" style="font-size:14px!important"  class="selectnav">
										<b >Nos congrès</b>
									</a>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</li>
				<li>
					<a href="/landingpage#propos" id="l2" >
						<b>Qui sommes nous ?</b>
					</a>
				</li>
				<li>
					<a href="/landingpage#speakers" id="l3" >
						<b>Bureau SMMIRP</b>
					</a>
				</li>
			</ul>
		</div>
	</nav>
</div>
</template>

<script>
export default {

  data: () => ({
    scrollPosition: null,
    slideshow:"",
    dialog:false,
    vid:"",
    audio:true,
    headclass:'sticky'
  }),
  methods: {
            updateScroll() {
                var header = document.getElementById("myHeader");
                var sticky = header.offsetTop;

                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky")
                } else {
                    header.classList.remove("sticky");
                }
            },
            goTo(path){
                this.$router.push(path)
            },
            click: function() {
                this.$refs.inscriComponent.setValue();
            },
        
    },
mounted() {
    window.addEventListener('scroll', this.updateScroll);

   
},
};
</script>
<style >
html{
  scroll-behavior: smooth;
}
.content {
  padding: 16px;
}

.sticky {
  position: absolute!important;
  position: fixed!important;
  width:100%;
  z-index: 9;
}


.first li a{
  color:#000!important;
  transition: 0.3s;
  font-size: 18px!important;
}
.first li a:hover{
  color:white!important;
  background-color:#2D84B9!important
}

.selectnav{
  color:#fff!important;
  transition: 0.3s;
  font-size: 2px!important;
}

#l1 {
	color:#B01185!important	
}
#l2 {
	color:#DF2530!important	
}
#l3 {
	color:#E99A3B!important	
}
#l4 {
	color:#1D8E50!important	
}
#l1:hover{
	color:#ffff!important	
}
#l2:hover {
	color:#ffff!important	
}
#l3:hover {
	color:#ffff!important	
}
#l4:hover {
	color:#ffff!important	
}

.LGactive li a{
  color:white!important;
  background-color:#E30525!important;
  padding:5px
}
.LGactive li a:hover{
  background-color:#0C3D58!important;
}
.logonav{
    width: 250px;
}
.footparashad{
	background: rgb(176,17,133,0.877);
	/* background: linear-gradient(90deg, rgba(176,17,133,0.877) 0%, rgba(223,37,48,0.877) 33%, rgba(233,154,59,0.877) 66%,rgba(29,142,80,0.877) 100%); */
	height: 350px;
}
.contactAdn{
  color: white!important;;
}
a:hover{
  color: #E4A837!important;;
}

.titleDateAs{
    font-size: 15px;
  }

.infofooter{
		font-family: 'Montserrat', sans-serif;
	}




@media (min-width: 1166px) and (max-width: 1361px) {
 
	.first li a{
		font-size: 14px!important;
	}
}

@media (min-width: 768px) and (max-width: 1165px) {
  .first li a{
		font-size: 15px!important;
	}

	.logonav{
      width:100px!important
    }

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .logonav{
  width: 190px;
}

.footpara {
     height: 320px!important;
    }

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .inscriBtn{
      width:130px
    }
	.liveBtn{
		width:250px
	}
    .text{
    font-size:3vw;
    text-justify: distribute;
    }
    .Logofoter{
      width:250px
    }
     .logonav{
      width: 150px;
    }
    .footpara {
     height: 645px!important;
    }
    .footparashad{
      height: 660px!important;
    }
	.infofooter{
		font-size: 14px;
		padding-left: 25px!important;
		padding-right: 17px!important;
	}
}


.footer{
    margin-top:100px;
}
.footText{
  color:white;
  text-align:right;
  font-size: 0.55em;
  color: #fff;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
}
v-img.sponsor{
  position: relative;
}
.footTitle{
    text-align:left;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.0rem;
    font-weight: 500
}
.titlefootline{
  content: "";
    display: block;
    width: 4.375rem;
    height: .25rem;
    background-color: red;
    bottom: 0;
    right: 0;
}
.social{
  position: absolute;
  right: 0px;
  position: fixed;
  width:50px;
  top: 30%;
  z-index: 9;
}
.floatlogo{
  position: absolute;
  left: 10px;
  position: fixed;
  width:200px;
  top: 7%;
  z-index: 9;
}
.navbar{
  margin-bottom:0px!important;
  z-index: 3!important;
}
.inscriBtn{
  background-color:#FBB400!important
}
.liveBtn{
  background-color:#ff0800!important;
  color:white!important
}

.liveBtn{
  color:#ffff!important
}
.vue-typer .custom.char.typed {
  color: #F19840;
  font-size: 20px;
}
.titleDate{
  font-size: 20px;
}
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .vue-typer .custom.char.typed {
    font-size: 10px;
  }
  .titleDate{
    font-size: 10px;
  }
  .titleDateAs{
    font-size: 10px;
  }

  .liveBtn{
	margin-bottom: 5px!important;
  }
}
</style>