<template>
  <div class="header">
    <v-img src="..\..\assets\Children.jpg" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
      aspect-ratio="2.7" class="img-responsive center">
      <v-container>
        <!-- <v-row align="center">
          <v-col cols="12" md="6" class="p-2">
            <v-row data-aos-once="true" data-aos="flip-left">
              <v-col cols="12" align="center">
                <v-hover v-slot="{ hover }">
                  <v-btn style="text-decoration: none;color:white!important" color="red" href="/congres2022" rounded
                    dark x-large>ACCÉDER AUX CONGRÈS
                    <v-icon class="ml-1" v-if="hover">
                      fa-arrow-right
                    </v-icon>
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
           <v-col cols="12" md="6" sm="12" @click="imgdialog = true"  align="center">
                    <image-shadow
                    :src="this.mySrc"
                    class="img-responsive center afficheAdn"
                    class-name="example"
                    :shadow-blur="10"
                    shadow-hover>
                    </image-shadow>
                    
                    <v-dialog class="cardimg" v-model="imgdialog" max-width="600">
                        <img  class="img-responsive center"   src="..\..\assets\congres22\4.jpg" alt="hakim">
                    </v-dialog>
                </v-col> 
        </v-row> -->
      </v-container>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'accueil',
  data() {
    return {
      now: Math.trunc((new Date()).getTime() / 1000),
      date: "2021-12-25 00:00:00",
      imgdialog: false,
      mySrc: '4.jpg',
      className: {
        type: String
      },
      shadowBlur: {
        type: Number,
        default: 20
      },
      shadowHover: {
        type: Boolean,
        default: false
      },
      shadowRadius: {
        type: Number,
        default: 8
      },
      src: {
        type: String,
        required: true
      },
      width: {
        type: Number,
        default: 300
      },
    }
  },

}
</script>
<style >
.block {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.tiemCard {
  padding: 10px;
  width: 500px
}

.digit {
  font-family: 'Roboto', serif;
  margin-top: 20%;
}

.view {
  text-align: center;
}

v-img.back {
  position: absolute;
  width: 100%;
}

nav.bottomNav {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  bottom: -19px;

}


/* Large desktop */
@media (min-width: 1200px) {}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {}

/* Landscape phones and down */
@media (max-width: 480px) {
  .time {
    font-size: 0.7em
  }

  .digit {
    margin-top: 25%;
  }

  .tiemCard {
    width: 400px
  }

  .datetime {
    font-size: 1.3em
  }
}

.flip-clock__slot {
  color: #cca900;
}
</style>
