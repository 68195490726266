<template>
    <div>
        <v-container grid-list-xs>
         <v-row>
            <v-col align="center">
                <h1 class="propsHead compHead" style="color:#1D8E50;font-family: 'Montserrat'"  >
                    SPONSORS
                </h1>
                <v-divider></v-divider>
            </v-col>
			</v-row>
        <v-row class="mt-2 mb-5">
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                    <img  class="img-responsive " src="../../assets/congres22/sponsors-01.png"  width="300" >
                    </a>
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                    <img  class="img-responsive " src="../../assets/congres22/sponsors-02.png"  width="300" >
                    </a>
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                        <img  class="img-responsive " src="../../assets/congres22/sponsors-03.png"  width="300" >
                    </a>
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                    <img  class="img-responsive " src="../../assets/congres22/sponsors-04.png"  width="300" >
                    </a>
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                    <img  class="img-responsive " src="../../assets/congres22/sponsors-05.png"  width="300" >
                    </a>
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                        <img  class="img-responsive " src="../../assets/congres22/sponsors-06.png"  width="300" >
                    </a>
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                    <img  class="img-responsive " src="../../assets/congres22/sponsors-07.png"  width="300" >
                    </a>
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                    <img  class="img-responsive " src="../../assets/congres22/sponsors-08.png"  width="300" >
                    </a>
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                        <img  class="img-responsive " src="../../assets/congres22/sponsors-09.png"  width="300" >
                    </a>
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                    <a href="#" target="_blanc">
                        <img  class="img-responsive " src="../../assets/congres22/sponsors-10.png"  width="300" >
                    </a>
                </v-flex>
            </v-layout>
            </v-col>
        </v-row>
        </v-container>
    </div>  
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>
<style>

    .partnername{
        font-family: 'Montserrat', sans-serif;
    }


@media (max-width: 767px) {
    .partnername{
      font-size: 1.3rem!important;  
    }
}

</style>