<template>
	<div>
		<v-container grid-list-xs>
			<v-row>
				<v-col align="center">
					<h1 class="propsHead compHead" style="color:#DF2530;font-family: 'Montserrat'">
						Mot de clôture
					</h1>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="pa-1" cols="12">
					<v-card class="rounded-xl" elevation="24" data-aos-once="true" data-aos="fade-down"
						data-aos-duration="1500">
						<div style="text-align: left;background-color:white;text-align: justify;text-justify: inter-word;transition: all 4s;font-family: 'Montserrat', sans-serif;"
							class="pa-5 text cardtext">
							<v-row class="mt-20">
								<v-col cols="12">
									<b style="color: #2D84B9;">Chers participants,</b><br><br>
									Le premier congrès de la SMMIRP a connu grâce à vous un franc succès. Les échanges y
									étaient
									fructueux, ouverts et intéressants. Que de belles énergies.<br><br>
									Aussi, je remercie tous les membres du bureau de m’avoir accompagnée dans le choix
									pertinent
									des thématiques du programme scientifique.<br><br>
									Au nom de tous les membres de la SMIIRP, je remercie les intervenants pour la
									qualité de leurs
									présentations, un contenu consistant et une éloquence qui ont maintenu l’assistance
									en haleine et
									suscité beaucoup d’échanges parmi les participants.<br><br>
									Je remercie tous les participants dont la présence et l’intérêt porté aux
									thématiques ont rendu
									l’échange riche et de haut niveau.<br><br>
									Je réitère mes remerciements aux partenaires pharmaceutiques qui ont cru à ce
									projet, encouragé
									et soutenu sa réalisation.<br><br>
									Un grand merci à l’agence Ascrea et ses équipes pour le magnifique décor de scène et
									de la salle,
									la qualité de l’écran de projection et l’organisation magistrale, preuve d’un grand
									professionnalisme.<br><br>
									Deux personnes à remercier nominativement: Mme Le professeur Sakhi Asmaa,
									rhumatologue
									pédiatre à Casablanca au sein de mon équipe et trésorière de la SMMIRP, qui a
									travaillé à mes
									cotés, de bout en bout, à l’organisation du congrès, ainsi que à Mme Rahmouni
									Batoula,
									secrétaire du service qui a géré toutes vos inscriptions, répondu à vos questions et
									mails avec
									beaucoup de patience et de gentillesse. Un merci chaleureux aux autres membres
									permanents de
									mon équipe qui ont assuré consciencieusement la supervision des patients pendant la
									phase
									d’organisation et de déroulement du congrès.<br><br>
									Mille mercis à Mme Yasmina Sbihi, architecte soufi d’avoir apporté une note de rêve
									et de
									spiritualité à notre congrès. Elle a su toucher les coeurs.<br><br>
									Sans vous toutes et tous, nous n’aurions pu réussir cet événement.<br><br>
									J’espère que cet air de bienveillance et de convivialité sera au rendez-vous lors
									des prochaines
									éditions.<br><br>
									Tous ces efforts ont pour but unique de prendre soin des patients pédiatriques
									atteints de maladies
									rhumatologiques dans un système vertueux où la science fait foi.<br><br>
								</v-col>
								<v-col cols="12">
									<b style="color: #2D84B9;text-align: right;">Kenza Bouayed <br>
										Présidente de la SMMIRP et présidente du 1er congrès de la SMMIRP</b>
								</v-col>
							</v-row><br><br>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
export default {
	data() {
		return {
			more: false
		}
	},
}
</script>
<style >
.cardtext {
	font-size: 1.2em;
	color: #666666;
	border: 5px solid #DF2530;
}

.propo {
	text-align: center;
}

.hashtageimg {
	width: 200px;
}

@media all and (max-width: 768px) {

	.propsHead {
		font-size: 1.5rem;
		padding: .75rem;
		color: #EC5713
	}

	.hashtageimg {
		width: 120px;
	}

	.cardtext {
		font-size: 0.8em !important;
	}
}

@media all and (max-width: 375px) {

	.hashtageimg {
		width: 120px;
	}
}
</style>