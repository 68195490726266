import Vue from 'vue';
import VueRouter from 'vue-router';
import index from './components/index';
import indexTablerond from './components/tableronde/index';
import congres22 from './components/congres22';
import congres2022jour1 from './components/congres22/jour1';
import congres2022jour2 from './components/congres22/jour2';

Vue.use(VueRouter);

export default new VueRouter({
        mode:'history',
        routes:[
            {
                path:'/',
                redirect: '/landingpage'
            },
            {
                path:'/landingpage',
                component:index,
                
            },
            {
                path:'/tableronde',
                component:indexTablerond,
            },
            {
                path:'/congres2022',
                component:congres22,
                children: [
                    {
                      path: 'jour1',
                      component: congres2022jour1
                    },
                    {
                      path: 'jour2',
                      component: congres2022jour2
                    },
                    {
                        path: '/',
                        redirect: 'jour1'
                    },
                ]
            },
            {   path: '*',
                redirect: '/landingpage'
            }
            
        ]
});
