<template>
    <v-app>
        <congres22-header></congres22-header>
    
        <div  data-spy="scroll" data-target="#navbar-app" data-offset="0">
            <div >
                <accueil/>
            </div>
            <div >
                <timer/>
            </div>
            <div id="propos" class="mt-4">
                <propos/>
            </div>
            <div id="theme" class="mt-4">
                <thematique/>
            </div>
            <div id="programme">
                <agenda class="mt-5" :slideshow="slideshow"/>
            </div>
            <div id="speakers">
                <speakers class="mt-5 "/>
            </div>
            <div id="sponsors">
                <sponsors class="mt-5 "/>
            </div>
        </div>
    
        <congres22-footer></congres22-footer>
    </v-app>
    </template>
    
    <script>
    import congres22Header from './congres22/header.vue';
    import accueil from './congres22/accueil.vue';
    import timer from './congres22/timer.vue'
    import thematique from './congres22/thematique.vue';
    import propos from './congres22/propos.vue';
    import agenda from './congres22/agenda.vue';
    import speakers from './congres22/speakers.vue';
    import sponsors from './congres22/sponsors.vue';
    import congres22Footer from './congres22/footer.vue';
    export default {
      components: { thematique,accueil,congres22Header,congres22Footer,sponsors,timer,agenda,propos,speakers },
    
      data: () => ({
        scrollPosition: null,
        slideshow:"",
        dialog:false,
        vid:"",
        audio:true
      }),
      methods: {
        updateScroll() {
           this.scrollPosition = window.scrollY
        },
        goTo(path){
               this.$router.push(path)
            },
        click: function() {
            this.$refs.inscriComponent.setValue();
        },
    
        slidenum(num){
          this.slideshow=num
         
        },
      },
    mounted() {
    },
    
    };
    </script>
    <style >
    </style>