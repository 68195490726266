<template>
    <div>
        <v-container>
            <v-container grid-list-xs>
                <v-row>
                    <v-col align="center">
                        <h1 class="propsHead compHead" style="color:#E99A3B;font-family: 'Montserrat'">
                            Evénements SMMIRP
                        </h1>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>



                <v-row class="mt-10 mb-5">


                    <v-col align="center" elevation="19" data-aos-once="true" data-aos="fade-down"
                        data-aos-duration="1500">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="310">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>

                            <v-img height="500" src="..\..\assets\invitation AMMIRP-01.jpg"></v-img>

                            <v-card-title>Rhumatologie Pédiatrique et COVID 19</v-card-title>

                            <v-card-text>
                                <v-row align="center" class="mx-0">

                                </v-row>




                            </v-card-text>

                            <v-divider class="mx-4"></v-divider>



                            <v-card-text>

                            </v-card-text>

                            <v-card-actions>
                                <div>
                                    <v-chip class="ma-2" color="green" text-color="white" label>
                                        <v-icon left>
                                            mdi-calendar-month-outline
                                        </v-icon>
                                        Mercredi 26 Mai 2021
                                    </v-chip>
                                    <v-chip class="ma-2" color="cyan" label text-color="white">
                                        <v-icon left>
                                            mdi-map-marker
                                        </v-icon>
                                        En ligne (Webinaire)
                                    </v-chip>
                                    <v-chip style="text-decoration: none;" target="_blanc"
                                        href="https://www.youtube.com/watch?v=K_yhxM93bH4&ab_channel=smmirpcongr%C3%A8s"
                                        class="ma-2" color="red" label text-color="white">
                                        <v-icon>mdi-youtube</v-icon>
                                        Cliquez ici pour revoir le Webinaire
                                    </v-chip>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-col>


                    <v-col align="center" elevation="19" data-aos-once="true" data-aos="fade-down"
                        data-aos-duration="1500">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="330">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>

                            <v-img height="500" src="..\..\assets\invitation SMMIRP 01.jpg"></v-img>

                            <v-card-title>Maladie de Behcet Pédiatrique sous tous ses aspects</v-card-title>

                            <v-card-text>
                                <v-row align="center" class="mx-0">

                                </v-row>




                            </v-card-text>

                            <v-divider class="mx-4"></v-divider>



                            <v-card-text>

                            </v-card-text>

                            <v-card-actions>
                                <div>
                                    <v-chip class="ma-2" color="green" text-color="white" label>
                                        <v-icon left>
                                            mdi-calendar-month-outline
                                        </v-icon>
                                        Vendredi 01 Avril 2022
                                    </v-chip>
                                    <v-chip class="ma-2" color="cyan" label text-color="white">
                                        <v-icon left>
                                            mdi-map-marker
                                        </v-icon>
                                        Hôtel Le Casablanca - Casablanca<br>
                                    </v-chip>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-col>


                    <v-col align="center" elevation="19" data-aos-once="true" data-aos="fade-down"
                        data-aos-duration="1500">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="330">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>

                            <v-img height="500" src="..\..\assets\congres22\4.jpg"></v-img>

                            <v-card-title>1ER CONGRÈS NATIONAL DE LA SMMIRP <br>14-15 Octobre 2022<br> L'Hôtel Hyatt
                                Regency<br> Casablanca </v-card-title>

                            <v-card-text>

                            </v-card-text>

                            <v-divider class="mx-4"></v-divider>



                            <v-card-text>

                            </v-card-text>

                            <v-card-actions>
                                <v-hover v-slot="{ hover }">
                                    <v-btn style="text-decoration: none;color:white!important" color="red"
                                        href="/congres2022" rounded dark x-large>ACCÉDER AUX CONGRÈS
                                        <v-icon class="ml-1" v-if="hover">
                                            fa-arrow-right
                                        </v-icon>
                                    </v-btn>
                                </v-hover>
                            </v-card-actions>
                        </v-card>
                    </v-col>


                </v-row>
            </v-container>
        </v-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        hideHand() {
            this.animation = false
        }
    },

    mounted() {


    },
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.v-card--reveal-first {
    align-items: center;
    top: 120px;
    justify-content: center;
    position: absolute;
    width: 100%;
    font-size: 1.2em
}

.cvtext {
    font-size: 1.1em;
    color: #666666;
    white-space: pre-line
}

.sp1Img {
    border: 5px solid #B01185;
    border-radius: 50%;
    padding: 5;
}

.sp2Img {
    border: 5px solid #DF2530;
    border-radius: 50%;
    padding: 5;
}

.sp3Img {
    border: 5px solid #E99A3B;
    border-radius: 50%;
    padding: 5;
}

.sp4Img {
    border: 5px solid #1D8E50;
    border-radius: 50%;
    padding: 5;
}

.sp5Img {
    border: 5px solid #37AECE;
    border-radius: 50%;
    padding: 5;
}

@media (min-width: 800px) and (max-width: 1100px) {
    .cvtext {
        display: block;
        font-size: 1.1vw;
    }

    .v-card--reveal-first {
        font-size: 1em
    }
}

@media (max-width: 767px) {
    .cvtext {
        display: block;
        font-size: 0.9em;
    }

    .text-muted2 {
        font-size: 1.8em;
    }

    .text-muted {
        font-size: 1.4em;
    }
}

@media (max-width: 400px) {
    .cvtext {
        display: block;
        font-size: 0.8em;
    }
}

@media (max-width: 360px) {
    .cvtext {
        display: block;
        font-size: 0.7em;
    }

    .text-muted2 {
        font-size: 1.2em;
    }

    .text-muted {
        font-size: 1.1em;
    }
}

.carros {
    position: relative;
}

.pulsate {
    position: absolute;
    top: 50%;
    left: 12.5%;
    animation-name: pulsate;
    animation: pulsate 5s infinite;
    animation-duration: 5s;
}

@media (max-width: 480px) {
    .pulsate {
        left: 45%;
    }
}
</style>
<script>
  export default {
    data: () => ({
      loading: false,
      selection: 1,
    }),
    methods: {
      reserve () {
        this.loading = true
        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>